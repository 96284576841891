
import { defineComponent } from "vue";
import { passwordStrength, Options } from "check-password-strength";

const PASSWORD_STRENGTH_OPTIONS: Options<string> = [
  {
    id: 0,
    value: "Too weak",
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: "Too weak",
    minDiversity: 0,
    minLength: 8,
  },
  {
    id: 2,
    value: "Weak",
    minDiversity: 2,
    minLength: 8,
  },
  {
    id: 3,
    value: "Medium",
    minDiversity: 3,
    minLength: 10,
  },
  {
    id: 4,
    value: "Medium",
    minDiversity: 4,
    minLength: 8,
  },
  {
    id: 5,
    value: "Strong",
    minDiversity: 4,
    minLength: 10,
  },
  {
    id: 6,
    value: "Strong",
    minDiversity: 3,
    minLength: 12,
  },
];

export default defineComponent({
  name: "PasswordStrengthHint",
  props: {
    val: String,
  },
  data: function () {
    return {
    };
  },
  computed: {
    passwordStrength: function () {
      if (!this.val) {
        return -1;
      }

      if (this.val.length < 8) {
        return 0;
      }

      const str = passwordStrength(this.val, PASSWORD_STRENGTH_OPTIONS).value;

      switch (str) {
        case "Too weak":
          return 1;
        case "Weak":
          return 2;
        case "Medium":
          return 3;
        case "Strong":
          return 4;
        default:
          return -1;
      }
    },
  },
  methods: {
    getPasswordStrength: function (passwordStrength: number) {
      switch (passwordStrength) {
        case 0:
          return this.$t("Too weak");
        case 1:
          return this.$t("Very weak");
        case 2:
          return this.$t("Weak");
        case 3:
          return this.$t("Medium");
        case 4:
          return this.$t("Strong");
        default:
          return "";
      }
    },
  },
  mounted: function () { },
  beforeUnmount: function () { },
});
