
import { AppEvents } from "@/control/app-events";
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";
import { useVModel } from "../../utils/vmodel";

export default defineComponent({
  components: {},
  name: "MenuSideBar",
  emits: ["update:expanded"],
  props: {
    expanded: Boolean,
  },
  setup(props) {
    return {
      expandedStatus: useVModel(props, "expanded"),
    };
  },
  data: function () {
    return {
      page: '',
      isAuthenticated: AuthController.isAuthenticated(),
      isAdmin: AuthController.GlobalAdmin,
    };
  },
  methods: {
    updatePage: function () {
      this.page = this.$route ? (this.$route.name) : '';
    },

    onAuthChanged: function () {
      this.isAdmin = AuthController.GlobalAdmin;
    },
  },
  mounted: function () {
    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener("auth-status-changed",this.$options.onAuthChangedH);
    this.updatePage();
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("auth-status-changed",this.$options.onAuthChangedH);
  },
  watch: {
    $route: function () {
      this.updatePage();
    },
  },
});
