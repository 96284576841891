// Wallet controller

"use strict";

import { TransactionToSend } from "@/api/api-transactions";
import { AppEvents } from "./app-events";
import { parseBigInteger } from "@/utils/bigint";

declare global {
    interface Window { ethereum: any; }
}

export type TransactionSendError = "no-extension" | "no-wallet" | "network-mismatch" | "tx-error";
export class WalletController {
    public static HasExtension = false;
    public static Address = "";

    private static ExtensionCheckInterval = null;

    public static Initialize() {
        WalletController.ExtensionCheckInterval = setInterval(WalletController.CheckForExtension, 500);
        WalletController.CheckForExtension();
    }

    public static CheckForExtension() {
        if (!window.ethereum) {
            return;
        }

        window.ethereum.request({ method: 'eth_accounts' }).then(WalletController.HandleAccountChanged).catch(err => {
            console.error(err);
        });
    }

    public static ConnectWallet(callback?: () => void) {
        if (!window.ethereum) {
            if (callback) {
                callback();
            }
            return;
        }

        window.ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
            WalletController.HandleAccountChanged(accounts);
            if (callback) {
                callback();
            }
        }).catch(err => {
            if (err.code === 4001) {
                // EIP-1193 userRejectedRequest error
                // If this happens, the user rejected the connection request.
                console.log('User rejected the connection with the wallet');
            } else {
                console.error(err);
            }
            if (callback) {
                callback();
            }
        });
    }

    public static HandleAccountChanged(accounts: string[]) {
        if (!WalletController.HasExtension) {
            WalletController.HasExtension = true;
            window.ethereum.on('accountsChanged', WalletController.HandleAccountChanged);
            clearInterval(WalletController.ExtensionCheckInterval);
        }

        // console.log(accounts);

        if (accounts.length > 0) {
            WalletController.Address = accounts[0];
        } else {
            WalletController.Address = "";
        }

        AppEvents.Emit("wallet-changed");
    }

    public static EnsureWalletConnection(callback: () => void) {
        if (WalletController.Address) {
            return callback();
        }

        WalletController.ConnectWallet(callback);
    }

    public static SendTransaction(tx: TransactionToSend, callback: (err?: TransactionSendError, txHash?: string) => void) {
        if (!window.ethereum) {
            callback("no-extension");
            return;
        }

        WalletController.EnsureWalletConnection(() => {
            if (!WalletController.Address) {
                callback("no-wallet");
                return;
            }

            const currentExtNetwork = parseBigInteger(window.ethereum.networkVersion);
            const expectedNetwork = parseBigInteger(tx.network.chainId);

            if (currentExtNetwork !== expectedNetwork) {
                callback("network-mismatch");
                return;
            }

            window.ethereum.request({ 
                method: 'eth_sendTransaction',
                params: [
                    {
                        from: WalletController.Address,
                        to: tx.tx.to,
                        value: tx.tx.value,
                        data: tx.tx.data,
                    }
                ],
            }).then(txHash => {
                callback(null, txHash);
            }).catch(err => {
                console.error(err);
                callback("tx-error");
                return;
            });
            
        });
    }
}



