// Authentication API

import { GetAPIURL, RequestParams } from "@/utils/request";

export interface AuthContext {
    status: "UNAUTHORIZED" | "TFA_REQUIRED" | "USER_NOT_FOUND" | "LOGGED_IN";
    uid: string;
    account_type: "email" | "google";
    global_admin: boolean;
    permissions: string[];
    username: string;
    email: string;
    tfa: boolean;
    stripeEnabled: boolean;
    binanceEnabled: boolean;
}

export interface ThirdPartyLoginDetails {
    service: string;
    client_id: string;
    url: string;
}

export interface UserInfoMin {
    id: string;
    username: string;
}

export class AuthAPI {
    public static Context(): RequestParams<AuthContext> {
        return {
            method: "GET",
            url: GetAPIURL("/auth/context"),
        };
    }

    public static ThirdPartyLoginDetails(): RequestParams<ThirdPartyLoginDetails[]> {
        return {
            method: "GET",
            url: GetAPIURL("/auth/tp"),
        };
    }

    public static Login(username: string, password: string, captcha: string, remember: boolean): RequestParams<{uid: string, session_id: string}> {
        return {
            method: "POST",
            url: GetAPIURL("/auth/login"),
            json: {
                username: username,
                password: password,
                captcha: captcha,
                remember: remember ? "yes": "no",
            },
        };
    }

    public static Logout(): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/auth/logout"),
        };
    }

    public static CustomLogout(session: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/auth/custom_logout"),
            json: {
                id: session,
            },
        };
    }

    public static Signup(email: string, username: string, password: string, captcha: string): RequestParams<{uid: string, session_id?: string}> {
        return {
            method: "POST",
            url: GetAPIURL("/auth/signup"),
            json: {
                email: email,
                username: username,
                password: password,
                captcha: captcha,
            },
        };
    }
    
    public static ThirdPartySignup(uid: string, username: string, token: string): RequestParams<{uid: string, session_id?: string}> {
        return {
            method: "POST",
            url: GetAPIURL("/auth/signup/tp"),
            json: {
                id: uid,
                username: username,
                token: token,
            },
        };
    }

    public static TwoFactorAuthenticationLogin(captcha: string, token: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/auth/tfa"),
            json: {
                captcha: captcha,
                token: token,
            },
        };
    }

    public static VerifyEmail(uid: string, verification: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/auth/email/verify"),
            json: {
                uid: uid,
                verification: verification,
            },
        };
    }

    public static ForgotPassword(email: string, captcha: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/auth/password/forgot"),
            json: {
                email: email,
                captcha: captcha,
            },
        };
    }

    public static ResetPassword(uid: string, verification: string, password: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/auth/password/reset"),
            json: {
                uid: uid,
                verification: verification,
                password: password,
            },
        };
    }
}
