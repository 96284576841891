
import { AccountAPI } from "@/api/api-account";
import { AuthAPI } from "@/api/api-auth";
import { AppEvents } from "@/control/app-events";
import { Request } from "@/utils/request";
import { renderDateAndTime } from "@/utils/time-utils";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";

export default defineComponent({
  name: "DevicesModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      sessions: [],

      busy: false,
      error: "",
    };
  },
  methods: {
    autoFocus: function () {
      const elem = this.$el.querySelector(".auto-focus");
      if (elem) {
        setTimeout(() => {
          elem.focus();
        }, 200);
      }
    },

    open: function () {
      Request.Pending(
        "account-active-devices-load",
        AccountAPI.GetActiveSessions()
      )
        .onSuccess((sessions) => {
          this.sessions = sessions;
          this.displayStatus = true;
          this.autoFocus();
        })
        .onRequestError((err) => {
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          console.error(err);
        });
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    renderDate: function (t) {
      return renderDateAndTime(t, this.$t);
    },

    closeSession: function (s: string) {
      if (this.busy) {
        return;
      }

      this.busy = true;
      this.error = "";
      Request.Do(AuthAPI.CustomLogout(s))
        .onSuccess(() => {
          this.busy = false;
          this.sessions = this.sessions.filter(sessionData => {
            return sessionData.session !== s;
          });
          AppEvents.ShowSnackBar(this.$t("Remote device session removed"));
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err) => {
          this.busy = false;
          Request.ErrorHandler()
            .add(401, "*", () => {
              AppEvents.Emit("unauthorized");
            })
            .add(400, "*", () => {
              this.error = this.$t("Bad request");
            })
            .add(500, "*", () => {
              this.error = this.$t("Internal server error");
            })
            .add("*", "*", () => {
              this.error = this.$t("Could not connect to the server");
            })
            .handle(err);
        })
        .onUnexpectedError((err) => {
          this.error = err.message;
          console.error(err);
          this.busy = false;
        });
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("devices-list-open", this.$options.openH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("devices-list-open", this.$options.openH);
    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
