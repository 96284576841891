
import { AppEvents } from "@/control/app-events";
import { AppPreferences } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";
import { WalletController } from "@/control/wallet-controller";

export default defineComponent({
  name: "AccountSettingsModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      isAuthenticated: AuthController.isAuthenticated(),
      username: AuthController.Username,
      tfa: AuthController.RequiresTwoFactorAuthentication,
      admin: AuthController.GlobalAdmin,
      languages: AppPreferences.AvailableLanguages.slice(),
      lang: AppPreferences.Language,
      isDarkTheme: AppPreferences.Theme === "dark",

      hasWalletExtension: WalletController.HasExtension,
      walletAddress: WalletController.Address,
    };
  },
  methods: {
    open: function () {
      this.displayStatus = true;
      this.lang = AppPreferences.Language;
      this.isDarkTheme = AppPreferences.Theme === "dark";
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    logout: function () {
      AppEvents.Emit("logout-open");
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    onAuthChanged: function () {
      this.username = AuthController.Username;
      this.tfa = AuthController.RequiresTwoFactorAuthentication;
      this.admin = AuthController.GlobalAdmin;
      this.isAuthenticated = AuthController.isAuthenticated();
    },

    onWalletChanged: function () {
      this.hasWalletExtension = WalletController.HasExtension;
      this.walletAddress = WalletController.Address;
    },

    renderWalletAddress: function (a: string) {
      return a.substring(0, 8) + "..." + a.substring(a.length - 8);
    },

    copyWalletAddress: function () {
      navigator.clipboard.writeText(this.walletAddress);
      AppEvents.ShowSnackBar(this.$t("Wallet address copied to clipboard!"));
    },

    connectWallet: function () {
      if (this.hasWalletExtension) {
        WalletController.ConnectWallet();
      } else {
        // Show user that he has to connect using MetaMask
        AppEvents.ShowMessageModal(this.$t("Connect with extension"), this.$t("To connect your wallet, you need an extension that stores your wallet and signs the transactions. A well known wallet manager extension is Metamask."))
      }
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    renderLanguage: function (lang, languages) {
      for (let l of languages) {
        if (l.id === lang) {
          return l.name;
        }
      }
      return "-";
    },

    openSelectLanguage: function () {
      this.close();
      AppEvents.Emit("select-language-open");
    },

    renderTheme: function (isDark) {
      if (isDark) {
        return this.$t("Dark");
      } else {
        return this.$t("Light");
      }
    },

    openSelectTheme: function () {
      this.close();
      AppEvents.Emit("select-theme-open");
    },

    openChangeUsername: function () {
      AppEvents.Emit("change-username-open");
    },

    openChangeEmail: function () {
      AppEvents.Emit("change-email-open");
    },

    openChangePassword: function () {
      AppEvents.Emit("change-password-open");
    },

    openAccountDelete: function () {
      AppEvents.Emit("delete-account-open");
    },

    openDevicesList: function () {
      AppEvents.Emit("devices-list-open");
    },

    enableTFAOpen: function () {
      AppEvents.Emit("tfa-enable-open");
    },

    disableTFAOpen: function () {
      AppEvents.Emit("tfa-disable-open");
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("account-settings-open", this.$options.openH);

    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );

    this.$options.onWalletChangedH = this.onWalletChanged.bind(this);
    AppEvents.AddEventListener(
      "wallet-changed",
      this.$options.onWalletChangedH
    );

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("account-settings-open", this.$options.openH);
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );
    AppEvents.RemoveEventListener(
      "wallet-changed",
      this.$options.onWalletChangedH
    );
    if (this.$options.focusTrap) {
      this.$options.focusTrap.destroy();
    }
  },
  watch: {
    display: function () {
      if (this.display) {
        if (this.$options.focusTrap) {
          this.$options.focusTrap.activate();
        }
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        if (this.$options.focusTrap) {
          this.$options.focusTrap.deactivate();
        }
      }
    },
  },
});
