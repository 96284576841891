// Big integer utils

import { MetadataEntry } from "@/api/api-networks";
import Decimal from "decimal.js";

export function parseBigInteger(bi: string): bigint {
    try {
        return BigInt(bi);
    } catch (ex) {
        return BigInt(0);
    }
}

export function bigIntToHex(bi: bigint): string {
    return "0x" + bi.toString(16);
}

function getDecimals(metadata: MetadataEntry[]): number {
    for (const m of metadata) {
        if (m.key === "decimals") {
            return Number(parseBigInteger(m.value)) || 0;
        }
    }
    return 0;
}

export function renderBigIntegerQuantity(q: string, fungible: boolean, metadata: MetadataEntry[]): string {
    const bi = parseBigInteger(q);
    if (!fungible) {
        return bi.toString(10);
    }
    const decimals = getDecimals(metadata);

    if (decimals <= 0 || decimals > 128) {
        return bi.toString(10);
    }

    let decimal = new Decimal(bi.toString(10));

    decimal = decimal.div((new Decimal(10)).pow(new Decimal(decimals)));

    return decimal.toString();
}

export function getBigIntegerQuantity(q: string | number, fungible: boolean, metadata: MetadataEntry[]): bigint {
    if (typeof q === "number") {
        q = Math.floor(q) + "";
    }
    if (fungible) {
        const decimals = getDecimals(metadata);

        if (decimals <= 0 || decimals > 128) {
            return parseBigInteger(q);
        }

        try {
            const decimal = new Decimal(q);
            const decimalMod = decimal.mul((new Decimal(10)).pow(new Decimal(decimals)));
            const decimalFloor = decimalMod.floor();

            if (!decimalMod.equals(decimalFloor)) {
                throw new Error("Dropped decimals");
            }

            return BigInt(decimalFloor.toHex());
        } catch (ex) {
            console.error(ex);
            return BigInt(0);
        }
    } else {
        return parseBigInteger(q);
    }
}
