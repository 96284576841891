import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e8e8533"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "password-strength-container" }
const _hoisted_2 = {
  key: 0,
  class: "password-strength-hint"
}
const _hoisted_3 = { class: "password-strength-hint-left" }
const _hoisted_4 = { class: "password-strength-text" }
const _hoisted_5 = ["title"]
const _hoisted_6 = {
  key: 0,
  class: "fas fa-check password-strength-ok"
}
const _hoisted_7 = {
  key: 1,
  class: "fas fa-triangle-exclamation password-strength-warn"
}
const _hoisted_8 = {
  key: 2,
  class: "fas fa-times password-strength-error"
}
const _hoisted_9 = {
  key: 1,
  class: "password-strength-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.passwordStrength >= 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("Password strength")) + ": " + _toDisplayString(_ctx.getPasswordStrength(_ctx.passwordStrength)), 1)
          ]),
          _createElementVNode("div", {
            class: "password-strength-hint-right",
            title: _ctx.getPasswordStrength(_ctx.passwordStrength)
          }, [
            (_ctx.passwordStrength > 2)
              ? (_openBlock(), _createElementBlock("i", _hoisted_6))
              : (_ctx.passwordStrength > 0)
                ? (_openBlock(), _createElementBlock("i", _hoisted_7))
                : (_openBlock(), _createElementBlock("i", _hoisted_8))
          ], 8, _hoisted_5)
        ]))
      : _createCommentVNode("", true),
    (_ctx.passwordStrength >= 0 && _ctx.passwordStrength < 3)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t("Your password seems to be weak")) + ". " + _toDisplayString(_ctx.$t(
                    "Try using lowercase, uppercase, numbers and symbols, and also increasing your password length to increase its strength.",
                )), 1))
      : _createCommentVNode("", true)
  ]))
}