// Account API

import { GetAPIURL, RequestParams } from "@/utils/request";

export interface ActiveSession {
    session: string;
    created: number;
    remote: string;
    os: string;
    browser: string;
    current: boolean;
}

export class AccountAPI {
    public static ChangeUsername(username: string, tfaToken: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/username"),
            json: {
                username: username,
                tfa_token: tfaToken,
            },
        };
    }

    public static ChangeEmail(email: string, tfaToken: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/email"),
            json: {
                email: email,
                tfa_token: tfaToken,
            },
        };
    }

    public static ChangePassword(oldPassword: string, newPassword: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/password"),
            json: {
                old_password: oldPassword,
                new_password: newPassword,
            },
        };
    }

    public static GenerateTwoFactorAuthentication(): RequestParams<{secret: string, uri: string}> {
        return {
            method: "GET",
            url: GetAPIURL("/account/tfa/generate"),
        };
    }

    public static SetupTwoFactorAuthentication(secret: string, token: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/tfa/setup"),
            json: {
                secret: secret,
                token: token,
            },
        };
    }

    public static DisableTwoFactorAuthentication(token: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/tfa/disable"),
            json: {
                token: token,
            },
        };
    }

    public static GetActiveSessions(): RequestParams<ActiveSession[]> {
        return {
            method: "GET",
            url: GetAPIURL("/account/sessions"),
        };
    }

    public static CloseAllSessions(): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/sessions/close"),
        };
    }

    public static DeleteAccount(password: string, tfaToken: string): RequestParams<void> {
        return {
            method: "POST",
            url: GetAPIURL("/account/delete"),
            json: {
                password: password,
                tfa_token: tfaToken,
            },
        };
    }
}
