
import { defineComponent } from "vue";

import TopBar from "@/components/layout/TopBar.vue";
import MenuSideBar from "@/components/layout/MenuSideBar.vue";
import SnackBar from "@/components/layout/SnackBar.vue";

import LogoutModal from "@/components/modals/LogoutModal.vue";
import AccountSettingsModal from "@/components/modals/AccountSettingsModal.vue";
import ChangeLanguageModal from "@/components/modals/ChangeLanguageModal.vue";
import ChangeThemeModal from "@/components/modals/ChangeThemeModal.vue";
import ChangeUsernameModal from "@/components/modals/ChangeUsernameModal.vue";
import ChangeEmailModal from "@/components/modals/ChangeEmailModal.vue";
import ChangePasswordModal from "@/components/modals/ChangePasswordModal.vue";
import AccountDeleteModal from "@/components/modals/AccountDeleteModal.vue";
import DevicesModal from "@/components/modals/DevicesModal.vue";
import TwoFactorEnableModal from "@/components/modals/TwoFactorEnableModal.vue";
import TwoFactorDisableModal from "@/components/modals/TwoFactorDisableModal.vue";

import MessageModal from "@/components/modals/MessageModal.vue";

import { AuthController } from "@/control/auth";
import { AppEvents } from "@/control/app-events";
import { AppPreferences } from "@/control/app-preferences";

export default defineComponent({
  components: {
    TopBar,
    MenuSideBar,
    SnackBar,
    LogoutModal,
    AccountSettingsModal,
    ChangeLanguageModal,
    ChangeThemeModal,
    ChangeUsernameModal,
    ChangeEmailModal,
    ChangePasswordModal,
    AccountDeleteModal,
    DevicesModal,
    TwoFactorEnableModal,
    TwoFactorDisableModal,
    MessageModal,
  },
  name: "MainLayout",
  data: function () {
    return {
      sidebarExpanded: true,
      isDarkTheme: false,

      displayModalLogout: false,
      displayModalAccountSettings: false,
      displayModalLanguage: false,
      displayModalTheme: false,
      displayModalChangeUsername: false,
      displayModalChangeEmail: false,
      displayModalChangePassword: false,
      displayModalDeleteAccount: false,
      displayModalDevices: false,
      displayModalTwoFactorAuthEnable: false,
      displayModalTwoFactorAuthDisable: false,

      displayModalMessage: false,
      messageModalTitle: "",
      messageModalMessage: "",
    };
  },
  methods: {
    onAuthStatusUpdate: function () {
      this.loading = !AuthController.isAuthenticated();

      this.displayModalLogout = false;
      this.displayModalAccountSettings = false;
      this.displayModalChangeUsername = false;
      this.displayModalChangeEmail = false;
      this.displayModalChangePassword = false;
      this.displayModalDeleteAccount = false;
      this.displayModalDevices = false;
      this.displayModalTwoFactorAuthEnable = false;
      this.displayModalTwoFactorAuthDisable = false;
    },

    onThemeChanged: function () {
      this.isDarkTheme = AppPreferences.Theme === "dark";
    },

    toggleMenu: function () {
      this.sidebarExpanded = !this.sidebarExpanded;
    },

    closeSideBar: function () {
      this.sidebarExpanded = false;
    },

    skipMainContent: function (event) {
      if (event) {
        event.preventDefault();
      }
      const content: HTMLElement = document.querySelector(".page-content");
      if (content) {
        content.focus();
      }
    },

    showMessage: function (title: string, msg: string) {
      this.messageModalTitle = title;
      this.messageModalMessage = msg;
      this.displayModalMessage = true;
    },

    goBackToHome: function () {
      this.$router.push({ name: "home" });
    },
  },
  mounted: function () {
    if (document.documentElement.clientWidth < 1000) {
      this.sidebarExpanded = false;
    } else {
      this.sidebarExpanded = true;
    }

    this.$options.onAuthStatusUpdateH = this.onAuthStatusUpdate.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthStatusUpdateH
    );

    this.$options.onThemeChangedH = this.onThemeChanged.bind(this);
    AppEvents.AddEventListener("theme-changed", this.$options.onThemeChangedH);
    this.onThemeChanged();

    this.$options.showMessageH = this.showMessage.bind(this);
    AppEvents.AddEventListener("msg-modal", this.$options.showMessageH);

    this.$options.goBackToHomeH = this.goBackToHome.bind(this);
    AppEvents.AddEventListener("unauthorized", this.$options.goBackToHomeH);

    this.onAuthStatusUpdate();
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthStatusUpdateH
    );
    
    AppEvents.RemoveEventListener(
      "theme-changed",
      this.$options.onThemeChangedH
    );

    AppEvents.RemoveEventListener("msg-modal", this.$options.showMessageH);

    AppEvents.RemoveEventListener("unauthorized", this.$options.goBackToHomeH);
  },
  watch: {
    $route: function () {
      this.onAuthStatusUpdate();
      if (document.documentElement.clientWidth < 1000) {
        this.sidebarExpanded = false;
      } else {
        this.sidebarExpanded = true;
      }
    },
  },
});
