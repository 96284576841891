
import { AppEvents } from "@/control/app-events";
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  name: "TopBar",
  emits: ['toggle-menu'],
  data: function () {
    return {
      isAuthenticated: AuthController.isAuthenticated(),
    };
  },
  methods: {
    openUserSettings: function () {
      AppEvents.Emit("account-settings-open");
    },

    login: function () {
      AuthController.PageToGo = this.$route ? (this.$route.name || "home") : "home";
      AuthController.PageToGoParams = this.$route ? (this.$route.params || {}) : {};
      if (AuthController.isAskingForTwoFactor()) {
        this.$router.push({ name: "tfa-login" });
      } else {
        this.$router.push({ name: "login" });
      }
    },

    logout: function () {
      AppEvents.Emit("logout-open");
    },

    clickMenu: function () {
      this.$emit("toggle-menu");
    },

    onAuthChanged: function () {
      this.isAuthenticated = AuthController.isAuthenticated();
    },
  },
  mounted: function () {
    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );
  },
});
